import React from "react"
import Layout from "../../components/layout/Layout.js"
import { Row, BlogRow } from "../../components/row/Row.js"
import {
  Title,
  BlogTitle,
  SubHeading,
} from "../../components/title/Title.js"
import { TextMedium } from "../../components/text/Text.js"
import { Column50 } from "../../components/column/Column.js"
import Seo from "../../components/seo"
import img1 from "../../static/social-media-templates-saas.png"
import { Breadcrumb } from '../../components/breadcrumb/Breadcrumb'
import {
  Image,
} from "../../components/image/Image.js"
import { AboveFold, Section } from "../../components/section/Section.js"
import GumroadForm from "../../components/gumroadForm/GumroadForm.js"
import { Contact, ButtonContainer } from "../../components/button/Button.js"


function SocialMediaTemplate({ children, ...rest }) {
  return (
    <Layout>
      <Seo title="Social Media Templates for SaaS Companies" 
       description="This document includes 37 ready-to-use templates for social media posts. The purpose of these templates is to help you scale SM content production."
       image={img1}
       featuredImage={img1} />
      <AboveFold isLight>
        <Row>
        <Breadcrumb>
            <li>
              <a href="/content-strategy-templates/">All templates</a>
            </li>
            <li>
              <a href="/content-strategy-templates/social-media-templates-saas/">Social Media Templates for SaaS Companies</a>
            </li>
          </Breadcrumb>
        </Row>
        <Row isCentered>
          <Column50>
            <BlogTitle isPrimary title="Social Media Templates for SaaS Companies" />
            <SubHeading subheading="This document includes 37 ready-to-use templates for social media posts. The purpose of these templates is to help you scale SM content production." />
            <ButtonContainer>
              <Contact buttonText="Get the template" buttonLink="https://socontent.gumroad.com/l/social-media-templates-saas" />
            </ButtonContainer>
          </Column50>
          <Column50>
            <Image src={img1} alt="Social Media Templates for SaaS Companies" />
          </Column50>
        </Row>
      </AboveFold>
      
      <Section>
        <BlogRow isCentered>
          <TextMedium
              textMedium="Among others, you'll find templates for company announcements, for repurposing long-form content, for quotes from your thought 
              leaders, milestones, partnerships, and so on." /> 
          <TextMedium textMedium="You'll receive the .pdf document that gives you the overview, along with the set of images to serve as templates." /> 
          <GumroadForm 
                formName="Social media template"
                redirectPage="https://socontent.gumroad.com/l/social-media-templates-saas"
                buttonText="Get it on Gumroad"
                buttonLink="https://socontent.gumroad.com/l/social-media-templates-saas"
                // checkboxText="*Your consent is required. By checking this box, you agree to be contacted by us when we publish similar content." 
            />
        </BlogRow>
      </Section>
    </Layout>
  )
}

export default SocialMediaTemplate
